// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
// import axios from 'axios';
import api from './api';

const App = () => {
  const [user, setUser] = useState(null); // State for user authentication

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const resp = await api.get('/@me');
        if (resp.status === 200) {
          console.log(resp.data)
          setUser(resp.data); // Set user data
        } else {
          setUser(null);
        }
      } catch (error) {
        console.log("Not authenticated");
        setUser(null);
      }
    };
    checkAuth();
  }, []);

  return (
    <Router>
      <Navbar user={user} setUser={setUser} />
      <div className="container mt-5 pt-5">
        <Routes>
          <Route path="/" element={<Home user={user} />} />
          <Route path="/login" element={<Login setUser={setUser}/>} />
          <Route path="/sign-up" element={<SignUp />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
