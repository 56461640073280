// src/components/Navbar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
import api from '../api';
import logo from '../static/rt_logo.png';
import './Navbar.css';

const Navbar = ({ user, setUser }) => {

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Adjust the method or URL based on your API
      setUser(null); // Clear user state
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.log("Logout failed", error);
    }
  };


  return (
    <nav className="navbar navbar-expand-lg bg-light navbar-light">
      <a href="/" className="navbar-brand" style={{ maxWidth: '25%' }}>
        <img src={logo} className="img-fluid" id="brand-logo" alt="Brand Logo" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          {user ? (
            <>
              <li className="nav-item">
                <Link className="nav-link" to="/">App Home</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://realtorch.ai" target="_blank" rel="noopener noreferrer">Main Site</a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" onClick={handleLogout}>Logout</Link>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item">
                <a className="nav-link" href="https://realtorch.ai" target="_blank" rel="noopener noreferrer">Main Site</a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/login">Login</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/sign-up">Sign Up</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
