// src/components/Home.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'; 
import { Link } from 'react-router-dom';
import MarketEvaluator from './MarketEvaluator'; // Import MarketEvaluator
import ProductEvaluator from './ProductEvaluator'; // Import ProductEvaluator
import './Home.css';
import ClusteredListings from './ClusteredListings';

mapboxgl.accessToken = 'pk.eyJ1IjoidGtpbTcxMSIsImEiOiJjbHpyNTlrc2owbnduMmxweWNwMDVwdWlyIn0.Giqxjm8xnxmEiDyDQJtXbg';

const Home = ({ user }) => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDistance, setSelectedDistance] = useState(10);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedSection, setSelectedSection] = useState('market');

  useEffect(() => {
    if (user && user.is_admin) {
      fetchClients();
    }
  }, [user]);

  const fetchClients = async () => {
    try {
      const response = await api.get('/api/clients');
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    fetchLocations(clientId);
  };

  useEffect(() => {
    if (user) {
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchLocations = async (clientId) => {
    try {
      const response = await api.get('/get-locations', {
        params: { client_id: clientId }
      });
      setLocations(response.data);

      if (response.data.length > 0 && !selectedLocation) {
        setSelectedLocation(response.data[0].id);
      }

    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };
  
  const handleLocationClick = (locationId) => {
    setSelectedLocation(locationId);
  };
  
  const handleDistanceClick = (distance) => {
    setSelectedDistance(distance);
  };

  return (
    <div className="home-container">
      {user && user.is_admin && (
        <div className="client-selector">
          <label htmlFor="client-select">Select Client:</label>
          <select id="client-select" value={selectedClient || ''} onChange={handleClientChange}>
            <option value="" disabled>Select a client</option>
            {clients.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name} ({client.email})
              </option>
            ))}
          </select>
        </div>
      )}
      {user ? (
        <>
          <div className="content">
            <h1>Welcome, {user.email}!</h1>
            <p>Select a location and distance to view its charts.</p>
            <div className="section-buttons">
              <button onClick={() => handleSectionChange('market')} className={selectedSection === 'market' ? 'active' : ''}>Market Evaluator</button>
              <button onClick={() => handleSectionChange('product')} className={selectedSection === 'product' ? 'active' : ''}>Product Evaluator</button>
              <button onClick={() => handleSectionChange('cluster')} className={selectedSection === 'cluster' ? 'active' : ''}>Listings Evaluator</button>
            </div>

            <div className="sidebar">
              <h3>Select Location</h3>
              <div className="location-buttons">
                {locations.map((location) => (
                  <button
                    key={location.id}
                    onClick={() => handleLocationClick(location.id)}
                    className={`location-button ${selectedLocation === location.id ? 'active' : ''}`}
                  >
                    {location.name}
                  </button>
                ))}
              </div>
              <h3>Select Distance (miles)</h3>
              <div className="distance-buttons">
                {[10, 20, 30].map((distance) => (
                  <button
                    key={distance}
                    onClick={() => handleDistanceClick(distance)}
                    className={`distance-button ${selectedDistance === distance ? 'active' : ''}`}
                  >
                    {distance}
                  </button>
                ))}
              </div>
            </div>

            {selectedSection === 'market' && (
              <MarketEvaluator selectedLocation={selectedLocation} selectedDistance={selectedDistance} selectedClient={selectedClient} />
            )}
            {selectedSection === 'product' && (
              <ProductEvaluator selectedLocation={selectedLocation} selectedDistance={selectedDistance} selectedClient={selectedClient} />
            )}
            {selectedSection === 'cluster' && (
              <ClusteredListings selectedLocation={selectedLocation} selectedDistance={selectedDistance} selectedClient={selectedClient} />
            )}
          </div>
        </>
      ) : (
        <div className="home-container">
          <p>Please log in or sign up to see your application.</p>
          <div className="auth-buttons">
            <Link to="/login">
              <button className="auth-button">Login</button>
            </Link>
            <Link to="/sign-up">
              <button className="auth-button">Sign Up</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
