import React, { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import './ClusteredListings.css';
import api from '../api';
import DataTable from 'react-data-table-component';

mapboxgl.accessToken = 'pk.eyJ1IjoidGtpbTcxMSIsImEiOiJjbHpyNTlrc2owbnduMmxweWNwMDVwdWlyIn0.Giqxjm8xnxmEiDyDQJtXbg';

const ClusteredListings = ({ selectedLocation, selectedDistance, selectedClient }) => {
  const [selectedCategory, setSelectedCategory] = useState('sale');
  const [selectedPropertyType, setSelectedPropertyType] = useState('single_family');
  const [selectedBeds, setSelectedBeds] = useState(3);
  const [selectedCluster, setSelectedCluster] = useState(5);
  const [clusterData, setClusterData] = useState([]);
  const [map, setMap] = useState(null);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [transformedListings, setTransformedListings] = useState([]); // For table data

  const propertyTypes = {
    sale: {
      townhouse: [2, 3, 4],
      condo: [2, 3],
      single_family: [3, 4, 5]
    },
    rental: {
      apartment: [0, 1, 2, 3],
      townhouse: [2, 3, 4],
      condo: [2, 3],
      single_family: [3, 4, 5]
    }
    
  };

  
  // Fetch chart data
  const fetchChartsData = async (category, propertyType, beds) => {
  try {
    const baseChartName = `${category}_${propertyType}_${beds}_bed_cluster`;
    const chartNames = [
      `${baseChartName}_summary`,
      `${baseChartName}_listings`
    ];
    const response = await api.get(`/api/get-cluster-tables`, {
      params: {
        locationId: selectedLocation,
        selectedDistance,
        clusters: selectedCluster,
        client_id: selectedClient,
        chartNames: chartNames
      },
      paramsSerializer: params => {
        return Object.entries(params)
          .map(([key, value]) =>
            Array.isArray(value)
              ? value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&')
              : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join('&');
      }
    });

    const sortedCharts = response.data.sort((a, b) => {
      if (a.chart_name.includes('summary')) return -1;
      if (b.chart_name.includes('summary')) return 1;
      return 0;
    });


    const listingChart = sortedCharts.find(chart => chart.chart_name.includes('listings'));
    if (listingChart && listingChart.chart_js_json) {
      const parsedData = JSON.parse(listingChart.chart_js_json);
      
      // Set both the map data and table data
      const transformedListingsData = transformListingsData(parsedData);
      setTransformedListings(transformedListingsData);  // <-- Set transformed listings for table
      
      initializeMap(parsedData);  // For the map markers
    } else {
      setClusterData([])
        setTransformedListings([]); // Clear transformed listings for the table
        setMapMarkers([]); // Clear markers on the map
        clearMapMarkers(); // Ensure the map is cleared
    }
    

    const clusterChart = sortedCharts.find(chart => chart.chart_name.includes('summary'));
    if (clusterChart && clusterChart.chart_js_json) {
      const parsedClusterData = JSON.parse(clusterChart.chart_js_json);
      setClusterData(transformClusterData(parsedClusterData));
    }

  } catch (error) {
    console.error('Error fetching charts:', error);
      setTransformedListings([]);
      setClusterData([]);
      clearMapMarkers(); // Clear map on error
  }
};

useEffect(() => {
  if (selectedBeds !== null && selectedPropertyType && selectedDistance && selectedCluster) {
    fetchChartsData(selectedCategory, selectedPropertyType, selectedBeds);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [selectedCategory, selectedPropertyType, selectedBeds, selectedDistance, selectedCluster, selectedLocation, selectedClient]);


const clearMapMarkers = () => {
  // Remove all markers from the map
  mapMarkers.forEach(marker => marker.remove());
  setMapMarkers([]);
};

  const initializeMap = (listingsData) => {
    // Create the map if it doesn't exist yet
    if (!map) {
      const mapInstance = new mapboxgl.Map({
        container: 'map',
        center: [-95.989201, 40.746996], // Default center
        zoom: 14
      });
  
      // Wait for the map to load before setting markers
      mapInstance.on('load', () => {
        setMap(mapInstance);
        addMarkersToMap(listingsData, mapInstance);
      });
    } else {
      // If the map already exists, directly add markers
      addMarkersToMap(listingsData, map);
    }
  };
  
  const getClusterColor = (cluster) => {
    // Define colors for up to 15 clusters
    const colors = [
      'red',        // Cluster 1
      'blue',       // Cluster 2
      'green',      // Cluster 3
      'orange',     // Cluster 4
      'purple',     // Cluster 5
      'yellow',     // Cluster 6
      'pink',       // Cluster 7
      'brown',      // Cluster 8
      'cyan',       // Cluster 9
      'magenta',    // Cluster 10
      'lime',       // Cluster 11
      'indigo',     // Cluster 12
      'teal',       // Cluster 13
      'violet',     // Cluster 14
      'gold',       // Cluster 15
    ];
  
    // Return color based on cluster number or a default color
    return colors[cluster - 1] || 'gray'; // Default color if cluster is out of bounds
  };

  
  
  const addMarkersToMap = (listingsData, mapInstance) => {
    clearMapMarkers();// Clear existing markers
    
    const transformedListings = transformListingsData(listingsData);
  
    const newMarkers = transformedListings.map(listing => {
      if (listing.longitude && listing.latitude) {
        // Create a custom marker element
        const el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundColor = getClusterColor(listing.cluster); // Set color based on cluster
        el.style.width = '20px';
        el.style.height = '20px';
        el.style.borderRadius = '50%';
        el.style.border = '2px solid white';
  
        // Popup content: Display both address and price
        const popupContent = `
          <div>
            <strong>Address:</strong> ${listing.address} <br />
            <strong>Price:</strong> $${listing.price.toLocaleString()}  <!-- Format price with commas -->
          </div>
        `;
  
        // Add the marker with custom color and popup
        const marker = new mapboxgl.Marker(el)
          .setLngLat([listing.longitude, listing.latitude])
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent)) // Set popup with HTML content
          .addTo(mapInstance);
          
        // Open the popup when the marker is clicked
        el.addEventListener('click', () => {
          marker.togglePopup();  // This will open the popup on click
        });
  
        return marker;
      }
      return null;
    }).filter(marker => marker !== null); // Filter out any null markers
  
    setMapMarkers(newMarkers);
  
    // Fit map to markers if valid markers exist
    if (newMarkers.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();
      newMarkers.forEach(marker => {
        const lngLat = marker.getLngLat();
        bounds.extend(lngLat);
      });
      mapInstance.fitBounds(bounds, { padding: 50 });
    }
  };
  
  
  // Modify transformListingsData to handle null values
  const transformListingsData = (data) => {
    return Object.keys(data.latitude).map(listingId => {
      const latitude = data.latitude[listingId];
      const longitude = data.longitude[listingId];
  
      // Skip listings without valid longitude and latitude
      if (latitude === null || longitude === null) {
        return null;
      }
  
      return {
        listingId: listingId,
        latitude: latitude,
        longitude: longitude,
        address: data.formatted_address[listingId] || 'N/A', // Handle missing address
        bedrooms: data.bedrooms[listingId] || 'N/A',
        bathrooms: data.bathrooms[listingId] || 'N/A',
        cluster: data.cluster[listingId] || 'N/A',
        price: data.price[listingId] || 'N/A',
        year_built: data.year_built[listingId] || 'N/A',
        lot_size_sq_ft: data.lot_size_sq_ft[listingId] || 'N/A',
        lot_size_acres: data.lot_size_acres[listingId] || 'N/A'
      };
    }).filter(listing => listing !== null); // Filter out null listings
  };
  
  const formatZillowURL = (address) => {
    // Replace spaces with hyphens, remove commas, and append the "homes" Zillow base URL
    const formattedAddress = address
      .replace(/\s+/g, '-')    // Replace spaces with hyphens
      .replace(/,/g, '')       // Remove commas
      .replace(/--+/g, '-');   // Handle double hyphens (in case of multiple spaces)
  
    return `https://www.zillow.com/homes/${formattedAddress}`;
  };
  

  // Columns for the DataTable
  const columns = [
    {
      name: 'Cluster',
      selector: row => row.cluster,
      sortable: true,
      width: '100px', // Adjust width for the Cluster column
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: getClusterColor(row.cluster),
              marginRight: '10px'
            }}
          />
          {row.cluster}
        </div>
      )
    },
    {
      name: 'Address',
      selector: row => row.address,
      sortable: true,
      minWidth: '250px', // Set a minimum width, and allow the column to grow
    }
    ,
    {
      name: 'Bedrooms',
      selector: row => row.bedrooms,
      sortable: true,
      width: '110px', // Set a width for this column
    },
    {
      name: 'Bathrooms',
      selector: row => row.bathrooms,
      sortable: true,
      width: '110px', // Set a width for this column
    },
    {
      name: 'Price',
      selector: row => row.price,
      sortable: true,
      width: '150px', // Set a width for the Price column
    },
    {
      name: 'Year Built',
      selector: row => row.year_built,
      sortable: true,
      width: '120px', // Set a width for the Year Built column
    },
    {
      name: 'Lot Size - Sq Ft',
      selector: row => row.lot_size_sq_ft,
      sortable: true,
      width: '150px', // Set a width for the Lot Size column
    },
    {
      name: 'Lot Size - Acres',
      selector: row => row.lot_size_acres,
      sortable: true,
      width: '150px', // Set a width for the Lot Size column
    },
    {
      name: 'Zillow Link',
      cell: row => (
        <a
          href={formatZillowURL(row.address)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <button style={{ padding: '5px 10px', background: '#0077cc', color: 'white', border: 'none', borderRadius: '5px' }}>
            View on Zillow
          </button>
        </a>
      ),
      width: '150px', // Set a width for the Zillow Link column
    }
  ];
  
  
  
  const transformClusterData = (data) => {
    return Object.keys(data.Cluster).map(index => {
      const clusterNumber = data.Cluster[index];
      return {
        cluster: clusterNumber,
        avgPrice: data['Avg Price'][index],
        priceStdDev: data['Price Std Dev'][index],
        avgSquareFootage: data['Avg Square Footage'][index],
        squareFootageStdDev: data['Square Footage Std Dev'][index],
        count: data['Count'][index],
        avgPricePerSqFt: data['Avg Price per Sq Ft'][index],
      };
    });
  };
  
  
  const clusterColumns = [
  {
    name: 'Cluster',
    selector: row => row.cluster,
    sortable: true,
    cell: row => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: getClusterColor(row.cluster),
            marginRight: '10px'
          }}
        />
        {row.cluster}
      </div>
    )
  },
  {
    name: 'Avg Price',
    selector: row => row.avgPrice ? row.avgPrice.toFixed(2) : 'N/A', // Check for null
    sortable: true
  },
  {
    name: 'Price Std Dev',
    selector: row => row.priceStdDev ? row.priceStdDev.toFixed(2) : 'N/A', // Check for null
    sortable: true
  },
  {
    name: 'Avg Square Footage',
    selector: row => row.avgSquareFootage ? row.avgSquareFootage.toFixed(2) : 'N/A', // Check for null
    sortable: true
  },
  {
    name: 'Square Footage Std Dev',
    selector: row => row.squareFootageStdDev ? row.squareFootageStdDev.toFixed(2) : 'N/A', // Check for null
    sortable: true
  },
  {
    name: 'Count',
    selector: row => row.count !== null && row.count !== undefined ? row.count : 'N/A', // Handle count being null
    sortable: true
  },
  {
    name: 'Avg Price per Sq Ft',
    selector: row => row.avgPricePerSqFt ? row.avgPricePerSqFt.toFixed(2) : 'N/A', // Check for null
    sortable: true
  }
];


  
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedPropertyType(Object.keys(propertyTypes[category])[0]);
    setSelectedBeds(null);
  };

  const handlePropertyTypeChange = (type) => {
    setSelectedPropertyType(type);
    setSelectedBeds(null);
  };

  const handleBedsChange = (beds) => {
    setSelectedBeds(beds);
  };

  const handleClusterChange = (clusterCount) => {
    setSelectedCluster(clusterCount);
  };

  return (
    <div>
      <div className="section-buttons">
       <button
          className={selectedCategory === 'sale' ? 'active' : ''}
          onClick={() => handleCategoryChange('sale')}
        >
          For Sale
        </button>
        <button
          className={selectedCategory === 'rental' ? 'active' : ''}
          onClick={() => handleCategoryChange('rental')}
        >
          Rent
        </button>
        
      </div>

      <div className="product-types-container">
        {Object.keys(propertyTypes[selectedCategory]).map((type) => (
          <div key={type} className="product-type-container">
            <h3>{type.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}</h3>
            <div className="product-type-buttons">
              {propertyTypes[selectedCategory][type].map((beds) => (
                <button
                  key={beds}
                  className={selectedBeds === beds && selectedPropertyType === type ? 'active' : ''}
                  onClick={() => {
                    handlePropertyTypeChange(type);
                    handleBedsChange(beds);
                  }}
                >
                  {beds} Bed
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      <h3>Select Clusters</h3>
      <div className="clustered-listings-buttons">
        {[5, 10, 15].map((clusterCount) => (
          <button
            key={clusterCount}
            onClick={() => handleClusterChange(clusterCount)}
            className={`cluster-button ${selectedCluster === clusterCount ? 'active' : ''}`}
          >
            {clusterCount} Clusters
          </button>
        ))}
      </div>

      <div id="map" style={{ width: '100%', height: '500px' }}></div>

      {/* Data Table */}

      <div className="table-container" style={{ marginTop: '20px' }}>
        <DataTable
          title="Listings Data"
          columns={columns}
          data={transformedListings}
          pagination
          noDataComponent="No listings for this filter"
          responsive
          customStyles={{
            rows: {
              style: {
                borderBottom: '1px solid #e0e0e0'  // Light border for rows
              },
            },
            headCells: {
              style: {
                borderBottom: '2px solid #e0e0e0',  // Thicker border for header cells
                fontWeight: 'bold'
              },
            },
          }}
        />
      </div>


      {/* Data Table for Cluster Summary */}
      <div className="table-container"  style={{ marginTop: '20px' }}>
        <DataTable
          title="Cluster Summary"
          columns={clusterColumns}
          data={clusterData}
          pagination
          noDataComponent="No listings for this filter"
          customStyles={{
            rows: {
              style: {
                borderBottom: '1px solid #e0e0e0'  // Light border for rows
              },
            },
            headCells: {
              style: {
                borderBottom: '2px solid #e0e0e0',  // Thicker border for header cells
                fontWeight: 'bold'
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ClusteredListings;
