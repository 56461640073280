// src/components/Login.js
import React, { useState } from 'react';
// import axios from 'axios';
import api from '../api';
import { useNavigate } from 'react-router-dom';

const Login = ({ setUser, setMessages }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { email, password }, {
        headers: { 'Content-Type': 'application/json' }
      });
      // setMessages([{ text: response.data.message, category: response.data.success ? 'success' : 'error' }]);
      if (response.data.success) {
        console.log("user has logged in")
        console.log(response.data)
        setUser(response.data.user); // Update the user state
        navigate('/');
      }
    } catch (error) {
      alert("Invalid Credentials");
      // setMessages([{ text: 'An error occurred. Please try again.', category: 'error' }]);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h3 align="center">Login</h3>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <br />
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Login;
